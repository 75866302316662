import React, { useState } from 'react';
import './index.scss';
import { authenticateRequest } from '../../services/api';

type Props = {
    token: string;
    setToken: (token: string) => void;
    authenticate: () => void;
}

const Auth: React.FC<Props> = ({
    token,
    setToken,
    authenticate
}) => {

    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const startAuthentication = () => {
        setIsAuthenticating(true)
        setError(false)
        authenticateRequest(token.trim())
            .then((didAuth) => {
                setIsAuthenticating(false)
                if (didAuth) {
                    authenticate()
                    setError(false)
                } else {
                    setError(true)
                }
            })
            .catch((e) => {
                setIsAuthenticating(false)
                setError(true)
                console.log(e)
            })
    }

    return (
        <div className='auth'>

            <div className='container'>

                <h1 >Welcome,<br/>Enter your access key to log in</h1>
                {error && <p className='error' >Invalid Access Key</p>}
            </div>

            <input
                placeholder='Access Key'
                type="text"
                value={token}
                onChange={(e) => setToken(e.target.value.trim())}
            />

            <button onClick={startAuthentication} >{isAuthenticating? "Logging In..." : "Log In"}</button>


        </div>
    );
}

export default Auth;
