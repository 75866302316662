import React, { FC } from "react"
import downloadIcon from '../../assets/download.png';
import './index.scss';
import { Document, downloadDocumentRequest } from "../../services/api";
import { useState } from "react";

type Props = {
    result?: Document,
    token: string
}

const Result: FC<Props> = ({ result, token }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);


    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const downloadClick = async () => {
        if (result) {
            setIsDownloading(true);
            try {
                await downloadDocumentRequest(result, token.trim());
            } catch (error) {
                console.error("Download failed", error);
            } finally {
                setIsDownloading(false);
            }
        }
    };



    if (!result) {
        return (
            <li className="result loading">
                <div className="title" />
                <div className='row' >
                    <div className='body' ><p/></div>
                </div>
            </li>
        )
    }

    return (
        <li className="result">
            <h3 className="title">{result?.formattedId}</h3>
            <div className='row'>
            <div className='body'>
                <p className={isExpanded ? 'expanded' : ''}>{result?.formattedTopChunkText}</p>
                <div className="gradient-overlay"></div>
                {result?.formattedTopChunkText && result.formattedTopChunkText.length > 240 && (
                    <button onClick={toggleExpand} className="read-more">
                    {isExpanded ? 'Read Less' : 'Read More'}
                    </button>
                )}
            </div>
            {isDownloading ? (
                    <div className="download loading">
                        <div className="loading-spinner"></div>
                    </div>
                ) : (
                    <a className="download" onClick={downloadClick}>
                        <img src={downloadIcon} className="icon" alt="Download PDF icon" />
                        DOWNLOAD<br />CONTENT
                    </a>
                )}
            </div>
        </li>
    );
};

export default Result