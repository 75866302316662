import axios from "axios"
import FileDownload from 'js-file-download';


export interface Document {
    id: string,
    formattedId: string,
    score: number,
    topChunkText: string,
    formattedTopChunkText: string,
    text: string,
    documentClass: string,
}

export interface Search {
    id: string,
    documents: Document[],
}

export enum Engine {
    alfa = "alfa",
    beta = "beta",
    gamma = "gamma",
}

// const baseUrl = window.location.origin.toString()
const baseUrl = "https://api2.lawlabs.se"

const mapSearchRequest = (fetched: any): Search | null => {
    const id = fetched.search_id
    
    if (!id) {
        return null
    }

    const documents = fetched.docs
    if (!Array.isArray(documents)) {
        return null
    }

    return {
        id,
        documents: documents.map((doc: any) => ({
            id: doc.document_id,
            formattedId: doc.formatted_document_id,
            score: doc.score,
            topChunkText: doc.top_chunk_text,
            formattedTopChunkText: doc.formatted_top_chunk_text,
            text: doc.text,
            documentClass: doc.document_class,
        }))
    }
}

export const searchRequest = async (query: string, n: number, accessToken: string, engine: Engine): Promise<Search | null> => {
    const response = await axios.post(`${baseUrl}/database/search`, {
            query: query,
            docs_count: n,
            engine: engine,
        }, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }
    )
    return mapSearchRequest(response.data)
}

export const extendSearchRequest = async (searchId: string, n: number, accessToken: string, engine: Engine): Promise<Search | null> => {
    const response = await axios.post(`${baseUrl}/database/search/extend`, {
            search_id: searchId,
            docs_count: n,
            engine: engine,
        }, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }
    )

    return mapSearchRequest(response.data)
}

export const authenticateRequest = async (token: string): Promise<boolean> => {
    const response = await axios.get(`${baseUrl}/auth`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        }
    )

    return !!response.data?.auth
}

export const isAdminRequest = async (token: string): Promise<boolean> => {
    const response = await axios.get(`${baseUrl}/admin`, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })
    
    return !!response.data?.admin
}

export const getDefaultEngineRequest = async (token: string): Promise<Engine> => {
    const response = await axios.get(`${baseUrl}/engines/default`, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })

    return response.data?.engine
}

export const getEnginesRequest = async (token: string): Promise<Engine[]> => {
    const response = await axios.get(`${baseUrl}/engines`, {
        headers: {
            "Authorization": `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    })

    return response.data?.engines
}

export const downloadDocumentRequest = async (document: Document, token: string) => {
    try {
        const response = await axios.get(`${baseUrl}/database/${document.documentClass}/download/${document.id}`, {
            headers: {
                "Authorization": `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob'
        });
        FileDownload(response.data, document.formattedId + ".pdf");
    } catch (error) {
        console.error("Download failed", error);
        throw error;
    }
}

