import { useEffect, useRef, useState } from 'react'

const useApiRequest = (requestFunc: () => Promise<void>, dependencies: any[]) => {
    const [loadingState, setIsLoadingState] = useState({
        isLoading: false,
        error: null as null | any,
        hasLoaded: false
    })
    const requestDependencies = useRef<any[]>([...dependencies])

    const load = () => {
        requestDependencies.current = [...dependencies]
        setIsLoadingState(ls => ({ ...ls, isLoading: true }))
        requestFunc()
            .then(() => {
                setIsLoadingState(ls => ({ ...ls, isLoading: false, error: null, hasLoaded: true }))
            })
            .catch((error) => {
                setIsLoadingState(ls => ({ ...ls, isLoading: false, error }))
            })
    }

    useEffect(() => {
        if (!loadingState.isLoading) {
            if (requestDependencies.current.some(((d, i) => d !== dependencies[i]))) {
                load()    
            }
        }

    }, [loadingState, ...dependencies])

    return {
        ...loadingState,
        reload: load
    }
}

export default useApiRequest