import React, { ChangeEventHandler, useEffect, useState, useRef } from 'react';
import logo from '../assets/logo.png';
import searchIcon from '../assets/search.png';
import { LoremIpsum } from "lorem-ipsum";
import useApiRequest from '../useApiRequest';
import './index.scss';
import Result from './Result';
import { searchRequest, Document, extendSearchRequest, Engine, isAdminRequest, getDefaultEngineRequest, getEnginesRequest } from '../services/api';
import Auth from './Auth';
import CryptoJS from 'crypto-js';

// const lorem = new LoremIpsum();
// const accessToken = "BDcbDhBIZn4KY6cKFy3ac8mZthc8DyAYzdanHEmJL245IffDp28T8akBK5UBF4c0"
// const dummyFile = "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"

const SECRET_KEY = "dalkwdziwpezsworenfoskro"

function Page() {
    const uploadFileRef = useRef<HTMLInputElement>(null)
    const textareaRef = useRef<HTMLTextAreaElement>(null)

    const [filePath, setFilePath] = useState<string>("")
    const [text, setText] = useState<string>("")
    const [results, setResults] = useState<Document[]>([])
    const [loadFrom, setLoadFrom] = useState<number>(0)
    const [loadAmount, setLoadAmount] = useState<number>(5)
    const searchIdRef = useRef<string | null>()
    const [accessToken, setAccessToken] = useState<string>("")
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [canLoadMore, setCanLoadMore] = useState<boolean>(true)
    const [engine, setEngine] = useState<Engine>(Engine.beta)
    const [engines, setEngines] = useState<Engine[]>([]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);


    const hasSearched = filePath || text.trim()

    const loadState = useApiRequest(async () => {
        if (!hasSearched || !accessToken) {
            setResults([])
            return
        }

        if (searchIdRef.current && loadFrom !== 0) {
            const search = await extendSearchRequest(searchIdRef.current, loadFrom, accessToken, engine)
            if (!search) {
                return
            }

            const newDocuments = search.documents.slice(loadFrom - loadAmount, loadFrom)

            setResults(results => [...results.slice(0, loadFrom), ...newDocuments])
            if (newDocuments.length < loadAmount) setCanLoadMore(false)
        } else {
            setLoadFrom(0)
            const search = await searchRequest(text, loadAmount, accessToken, engine)
            if (!search) {
                setResults([])
                return
            }
            setResults(search.documents)
            searchIdRef.current = search.id
        }
    }, [loadFrom])

    useEffect(() => {
        if (authenticated) {
            isAdminRequest(accessToken)
                .then(isAdmin => {
                    setIsAdmin(isAdmin);
                })
                .catch(error => {
                    console.error("Error checking admin status: ", error);
                });
        } else {
            setIsAdmin(false);
        }
    }, [authenticated, accessToken]);

    useEffect(() => {
        const loadEngines = async () => {
            try {
                const availableEngines = await getEnginesRequest(accessToken);
                setEngines(availableEngines);
                
                const defaultEngine = await getDefaultEngineRequest(accessToken);
                setEngine(defaultEngine);

            } catch (error) {
                console.error("Error loading engines:", error);
            }
        };

        if (accessToken) {
            loadEngines();
        }
    }, [accessToken]);

    const onTextAreaInput: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
        setText(e.target.value)
        setCanLoadMore(true)
        searchIdRef.current = null
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto'
            textareaRef.current.style.height = textareaRef.current?.scrollHeight + 'px'
        }
    }

    const onChangeFile: ChangeEventHandler<HTMLInputElement> = (e) => {
        setFilePath(e.target.value)
        setCanLoadMore(true)
        searchIdRef.current = null
    }

    const searchPress = () => {
        //uploadFileRef.current?.click()
        loadState.reload()
        setLoadFrom(0)
    }

    const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
        if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
            searchPress()
        }
    }

    const removeFileClick = () => {
        setFilePath("")
    }

    const showMoreClick = () => {
        setLoadFrom(loadFrom => loadFrom + 5)
    }

    const changeEngine = (engine: Engine) => {
        setEngine(engine)
        setLoadFrom(0)
    }

    useEffect(() => {
        const token = localStorage.getItem("accessToken")
        if (token) {
            setAccessToken(CryptoJS.AES.decrypt(token, SECRET_KEY).toString(CryptoJS.enc.Utf8))
            setAuthenticated(true)
        }
    }, [])

    const authenticate = () => {
        setAuthenticated(true)
        localStorage.setItem("accessToken", CryptoJS.AES.encrypt(accessToken, SECRET_KEY).toString())
    }

    const logOut = () => {
        setAuthenticated(false)
        setAccessToken("")
        localStorage.removeItem("accessToken")
    }

    const renderResult = (result: Document) => {
        return (
            <Result key={result.topChunkText} result={result} token={accessToken} />
        )
    }

    if (!authenticated) {
        return (
            <div className="page">
                <img
                    src={logo}
                    className="logo"
                    alt="LawLabs Logo"
                />

                <Auth
                    authenticate={authenticate}
                    token={accessToken}
                    setToken={setAccessToken}
                />
            </div>
        )
    }

    return (
        <div className="page" >
            <button
                className='log-out'
                onClick={logOut}
            >Log Out</button>
                <div className="dropdown">
                    <h4 className="selected">{engine}</h4>
                    {isAdmin && engines.length > 0 && (
                    <div className="options">
                        {engines.map((engine, i) => (
                        <button
                            key={i}
                            onClick={() => changeEngine(engine)}
                        >
                            {engine}
                        </button>
                        ))}
                    </div>
                    )}
                </div>
            

            <img
                src={logo}
                className="logo"
                alt="LawLabs Logo"
            />

            <p className='instructions' >Enter your query</p>

            <div className='input-row' >
                {!filePath &&
                    <div className='text-container' >
                        <textarea rows={1} ref={textareaRef} onInput={onTextAreaInput} onKeyDown={onKeyDown}></textarea>
                    </div>
                }

                <div className='file-container' style={filePath ? { flex: 1 } : {}} >
                    {filePath &&
                        <>
                            <button onClick={removeFileClick} className='remove' >X</button>
                            <h3>{filePath}</h3>
                            <div style={{ flex: 1 }} />
                        </>
                    }

                    <input type="file" accept=".pdf" onChange={onChangeFile} ref={uploadFileRef} />
                    <button className='upload' onClick={searchPress} >
                        <img
                            src={searchIcon}
                            className="icon"
                            alt="Upload PDF icon"
                        />
                    </button>
                </div>
            </div>


            {true &&
                <>
                    <div className='results-title'>
                        {loadState.isLoading ?

                            <h2>Lawlabs AI is searching for NJAs...<br /></h2>
                            :
                            loadState.hasLoaded && (loadState.error ?
                                <h2>There was an error loading the results</h2>
                                :
                                results.length === 0 ?
                                    <h2>Lawlabs AI did not find any NJAs matching your query</h2>
                                    :
                                    <h2>Lawlabs AI matched your query to the following NJAs</h2>
                            )
                        }
                    </div>

                    {loadState.isLoading ?
                        <ol className='results'>
                            {results.slice(0, loadFrom).map(renderResult)}
                            {Array(loadAmount).fill(0).map((_, i) => <Result key={i} token={accessToken} />)}
                        </ol>
                        : !loadState.error && results.length > 0 &&
                        <>
                            <ol className='results'  >
                                {results.map(renderResult)}
                            </ol>

                            {canLoadMore && <button onClick={showMoreClick} className='load-more' >LOAD MORE</button>}
                        </>
                    }

                </>
            }

        </div>
    )
}

export default Page;
